import {
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  startOfDay,
  endOfDay,
  differenceInMilliseconds,
  format,
  areIntervalsOverlapping,
  isSameDay,
  add,
} from "date-fns";

import LocalStorageService from "@services/localStorage";
import { APPOINTMENT_STATUSES, getStatus } from "@utils/status";
import { handleFreeTimeEventsIntersections } from "@utils/freeTimeEventsIntersections";
import { getWorkingHours } from "@utils/workingTime";
import { generateUUID } from "@utils/helpers";
import CalendarService from "@services/api/calendar";
import ProfileService from "@services/api/profile";
import { UserContext } from "@contexts/User";
import { USER_ROLES } from "@utils/consts";
import { useIntervalTrigger } from "./useIntervalTrigger";
import calendarGraphqlInstance from "@services/api/calendar.graphql";
import { AppointmentReportContext } from "@contexts/AppointmentReport";

const minute = 60000;

const convertData = (appointment) => ({
  ...appointment,
  ...getStatus(appointment.dtStart, appointment.status, appointment.dtEnd),
});

const prepareAppointment = async (
  appointmentParam,
  userProfile,
  includeSelf = false
) => {
  const appointment = { ...appointmentParam };

  if (appointment.participants && appointment.type !== "freeTime") {
    appointment.originalParticipants = appointment.participants;
    if (!includeSelf) {
      appointment.participants = appointment.participants.filter(
        (participant) => participant.userId !== userProfile.userId
      );
    }

    const participantProfiles = appointment.participants.map((participant) => {
      if (participant.userId) {
        return ProfileService.getProfileByUserId(
          participant.accountId,
          participant.userId
        );
      }
      return ProfileService.getProfileByExUserId(
        participant.accountId,
        participant.externalUserId
      );
    });
    appointment.participantProfiles = await Promise.all(participantProfiles);
  }

  return {
    ...appointment,
    id: appointment.id,
    dtConvertStart: new Date(Number(appointment.dtStart)),
    dtConvertEnd: new Date(Number(appointment.dtEnd)),
    profile:
      (appointment?.participantProfiles?.length &&
        (appointment.participantProfiles.find(
          (e) =>
            e?.userId ==
            appointment.participants.find((e) => e.type == "organizer")?.userId
        ) ||
          appointment.participantProfiles[0])) ||
      null,
    status: appointment.status,
    dtStart: Number(appointment.dtStart),
    dtEnd: Number(appointment.dtEnd),
  };
};

const participantProfileInAppointment = (
  appointmentParam,
  userProfile,
  includeSelf = false,
  participants
) => {
  const appointment = { ...appointmentParam };

  if (appointment.participants && appointment.type !== "freeTime") {
    appointment.originalParticipants = appointment.participants;
    if (!includeSelf) {
      appointment.participants = appointment.participants.filter(
        (participant) => participant.userId !== userProfile.userId
      );
    }
    const participantProfiles = appointment.participants.map((participant) =>
      participants.find((e) => e.userId === participant.userId)
    );
    appointment.participantProfiles = participantProfiles;
  }

  return {
    ...appointment,
    id: appointment.id,
    dtConvertStart: new Date(Number(appointment.dtStart)),
    dtConvertEnd: new Date(Number(appointment.dtEnd)),
    profile:
      (appointment?.participantProfiles?.length &&
        (appointment.participantProfiles.find(
          (e) =>
            e?.userId ==
            appointment.participants.find((e) => e.type == "organizer")?.userId
        ) ||
          appointment.participantProfiles[0])) ||
      null,
    status: appointment.status,
    dtStart: Number(appointment.dtStart),
    dtEnd: Number(appointment.dtEnd),
  };
};

const getMillisecondsFromDayStart = (date) =>
  differenceInMilliseconds(date, startOfDay(date));

const prepareTimeSlots = (
  orgWorkingHours,
  existingEvents,
  date,
  durationInMillis,
  freeTimeIntervals,
  isCounsellor = false,
  isNowTimeSlot = false
) => {
  if (
    !orgWorkingHours ||
    !orgWorkingHours.start ||
    !orgWorkingHours.end ||
    durationInMillis === "custom"
  ) {
    return [];
  }

  let freeTimes;
  if (freeTimeIntervals.length && !isCounsellor) {
    const freeSlots = freeTimeIntervals.filter(
      (freeTime) =>
        freeTime.dtEndFromDayStart > orgWorkingHours.start &&
        freeTime.dtStartFromDayStart < orgWorkingHours.end
    );
    if (!freeSlots.length) {
      return [];
    }

    freeSlots[0].dtStartFromDayStart =
      freeSlots[0].dtStartFromDayStart < orgWorkingHours.start
        ? orgWorkingHours.start
        : freeSlots[0].dtStartFromDayStart;

    freeSlots[freeSlots.length - 1].dtEndFromDayStart =
      freeSlots[freeSlots.length - 1].dtEndFromDayStart > orgWorkingHours.end
        ? orgWorkingHours.end
        : freeSlots[freeSlots.length - 1].dtEndFromDayStart;

    freeTimes = freeSlots.map((slot) => ({
      start: slot.dtStartFromDayStart,
      end: slot.dtEndFromDayStart,
    }));
  } else {
    if (isCounsellor) {
      freeTimes = [
        {
          start: isNowTimeSlot
            ? new Date().getTime() - startOfDay(new Date())
            : orgWorkingHours.start,
          end: orgWorkingHours.end,
        },
      ];
    } else {
      freeTimes = [];
    }
  }

  const restTimeInMillis = 600000; // 10 minutes of rest time

  const step = durationInMillis;
  const timeSlots = [];
  const now = Date.now();

  const isNowToday = isSameDay(now, date);
  const nowFromStartDay = now - startOfDay(now);

  freeTimes.forEach((freeTime) => {
    for (
      let currentTime = freeTime.start;
      currentTime < freeTime.end;
      currentTime += step + restTimeInMillis
    ) {
      const slotStartTime = currentTime;
      const slotEndTime = currentTime + step;

      if (isNowToday && currentTime < nowFromStartDay) {
        continue; // eslint-disable-line no-continue
      }

      const isOverlap = existingEvents.some((event) => {
        const eventInterval = {
          start: getMillisecondsFromDayStart(Number(event.dtStart)),
          end: getMillisecondsFromDayStart(Number(event.dtEnd)),
        };
        const slotInterval = {
          start: Number(slotStartTime),
          end: Number(slotEndTime),
        };

        return areIntervalsOverlapping(eventInterval, slotInterval, {
          inclusive: false,
        });
      });

      if (!isOverlap && slotEndTime < freeTime.end) {
        const dayStart = startOfDay(date).getTime();

        timeSlots.push({
          label: `${format(dayStart + slotStartTime, "HH:mm")} - ${format(
            dayStart + slotEndTime,
            "HH:mm"
          )}`,
          value: {
            dtStart: dayStart + slotStartTime,
            dtEnd: dayStart + slotEndTime,
          },
        });
      }
    }
  });

  return timeSlots;
};

export const useAppointmentList = (
  {
    isPopulateParticipants = false,
    dtStart = 0,
    dtEnd = 99999999999999,
    participantUserIds,
    isCacheEnabled = false,
    status = null,
    selectedUserProfile = null,
  } = {},
  filterFn
) => {
  const [appointments, setAppointments] = useState(() => {
    if (isCacheEnabled) {
      const cache = LocalStorageService.getAppointments();

      if (cache?.length) {
        return cache.map((v) => ({
          ...v,
          dtConvertStart: v.dtConvertStart ? new Date(v.dtConvertStart) : null,
          dtConvertEnd: v.dtConvertEnd ? new Date(v.dtConvertEnd) : null,
        }));
      }
    }

    return null;
  });

  const [isLoading, setIsLoading] = useState(true);
  const { userCalendar, profile } = useContext(UserContext);
  const isUnmounted = useRef();
  const intervalTrigger = useIntervalTrigger(minute);
  const isCacheMounted = useRef();
  const { appointmentSocket } = useContext(AppointmentReportContext);
  const selectedUserCalendar = useUserCalendar(
    selectedUserProfile?.accountId,
    selectedUserProfile?.userId
  );

  useEffect(() => {
    if (isCacheEnabled && appointments && isCacheMounted.current) {
      LocalStorageService.setAppointments(appointments);
      return;
    }

    isCacheMounted.current = true;
  }, [appointments]);

  const refetch = async () => {
    if (selectedUserProfile) {
      if (!selectedUserCalendar || !selectedUserProfile) {
        setIsLoading(false);
        return;
      }
    } else {
      if (!userCalendar || !profile) {
        setIsLoading(false);
        return;
      }
    }
    try {
      setIsLoading(true);
      const appointmentsResponse =
        await CalendarService.getCalendarEventsByCalendarId({
          accountId: selectedUserProfile
            ? selectedUserCalendar?.accountId
            : userCalendar.accountId,
          calendarId: selectedUserProfile
            ? selectedUserCalendar?.id
            : userCalendar.id,
          dtStart,
          dtEnd,
          populateParticipants: isPopulateParticipants,
          participantUserIds,
          status,
          isForSharedCalendar: true,
        });

      const participantsIds = appointmentsResponse.flatMap((appointment) => {
        const participants = appointment.participants?.filter((participant) => {
          return selectedUserProfile
            ? participant.userId !== selectedUserProfile?.userId
            : participant.userId !== profile.userId;
        });
        const result = participants?.map((participant) => {
          return {
            userId: participant.userId,
            accountId: participant.accountId,
          };
        });
        return result;
      });

      const externalParticipantsIds = appointmentsResponse.flatMap(
        (appointment) => {
          const participants = appointment.participants?.filter(
            (participant) => {
              return selectedUserProfile
                ? participant.userId !== selectedUserProfile?.userId
                : participant.userId !== profile.userId;
            }
          );
          const result = participants?.map((participant) => {
            return {
              externalUserId: participant.externalUserId,
              accountId: participant.accountId,
            };
          });
          return result;
        }
      );

      externalParticipantsIds.forEach((item) => {
        participantsIds.push(item);
      });

      let uniqueData = participantsIds.filter(
        (e) => e?.userId || e?.externalUserId
      );
      uniqueData = uniqueData.filter((item, index) => {
        const _item = JSON.stringify(item);
        return (
          index ===
          uniqueData.findIndex((obj) => {
            return JSON.stringify(obj) === _item;
          })
        );
      });

      let participantsData = uniqueData.map((participant) => {
        if (participant.userId) {
          return ProfileService.getProfileByUserId(
            participant.accountId,
            participant.userId
          );
        } else if (participant.externalUserId) {
          return ProfileService.getProfileByExUserId(
            participant.accountId,
            participant.externalUserId
          );
        }
        return null;
      });

      participantsData = await Promise.all(participantsData);

      let preparedAppointments = appointmentsResponse.map((appointmentItem) => {
        const participantProfile = participantProfileInAppointment(
          appointmentItem,
          selectedUserProfile ? selectedUserProfile : profile,
          false,
          participantsData
        );
        return participantProfile;
      });

      preparedAppointments = preparedAppointments
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) =>
          a.dtStart > b.dtStart ? 1 : b.dtStart > a.dtStart ? -1 : 0
        );

      if (filterFn && typeof filterFn === "function") {
        preparedAppointments = filterFn(preparedAppointments);
      }

      if (!isUnmounted.current) {
        setAppointments(preparedAppointments);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
    return () => {
      isUnmounted.current = true;
    };
  };

  useEffect(() => {
    refetch();
  }, [userCalendar, profile, dtStart, dtEnd, selectedUserCalendar]);

  const needUpdate = (msg) => {
    refetch();
  };

  useEffect(() => {
    if (!appointmentSocket || appointmentSocket.disconnected) {
      return;
    }
    appointmentSocket.on("appointment", needUpdate);
    return () => {
      appointmentSocket.off("appointment", needUpdate);
    };
  }, [appointmentSocket?.connected]);

  return useMemo(() => {
    const result = appointments
      ? appointments.map((item) => convertData(item))
      : null;
    return [result, { isLoading, refetch }];
  }, [appointments, intervalTrigger, isLoading]);
};

export const useAppointmentDetails = (
  id,
  isPopulateParticipants = false,
  isFromSharedCalendar = false,
  sharedCalendarSelectedProfile = null
) => {
  const [appointment, setAppointment] = useState(null);
  const { userCalendar, profile } = useContext(UserContext);
  const isUnmounted = useRef(false);
  const intervalTrigger = useIntervalTrigger(minute);
  const sharedCalendarSelectedUserCalendar = useUserCalendar(
    sharedCalendarSelectedProfile?.accountId,
    sharedCalendarSelectedProfile?.userId
  );

  useEffect(() => {
    (async () => {
      if (!userCalendar || !profile) {
        return;
      }

      const appointmentResponse = await CalendarService.getCalendarEventById(
        sharedCalendarSelectedUserCalendar
          ? sharedCalendarSelectedUserCalendar?.accountId
          : userCalendar.accountId,
        id,
        isPopulateParticipants,
        isFromSharedCalendar
      );

      const preparedAppointment = await prepareAppointment(
        appointmentResponse,
        sharedCalendarSelectedProfile ? sharedCalendarSelectedProfile : profile
      );
      if (!isUnmounted.current) {
        setAppointment(preparedAppointment);
      }
      return () => {
        isUnmounted.current = true;
      };
    })();
  }, [
    userCalendar,
    profile,
    sharedCalendarSelectedProfile,
    sharedCalendarSelectedUserCalendar,
  ]);

  return useMemo(() => {
    if (!appointment) return null;
    return convertData(appointment);
  }, [appointment, intervalTrigger]);
};

export const useAppointmentCreate = ({ isDataFetchingEnabled = true }) => {
  if (!isDataFetchingEnabled) {
    return [[], [], { isLoading: false }];
  }
  const { userCalendar, profile, user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const isCounsellor = user.roles.some(
    (role) => role.name === USER_ROLES.COUNSELLOR_ROLE
  );

  const createAppointment = useCallback(
    async (newAppointment, participantsProfiles, externalUsers) => {
      let mainUser = userCalendar;

      let participantProfiles = participantsProfiles || [];
      if (isCounsellor) {
        const userProfile =
          participantProfiles.find(
            (participant) => participant.userId === profile.userId
          ) || participantProfiles[0];

        mainUser = {
          userId: userProfile.userId,
          accountId: userProfile.accountId,
          id: userProfile.calendarId,
        };
        participantProfiles = participantProfiles.filter(
          (participant) => mainUser.userId !== participant.userId
        );
      }

      return new Promise(async (resolve, reject) => {
        setIsLoading(true);
        CalendarService.createCalendarEvent(mainUser.accountId, {
          accountId: mainUser.accountId,
          globalCalendarEventId: generateUUID(),
          calendarId: mainUser.id,
          organizerId: profile.userId,
          organizerName: profile.name || profile.username,
          title: "title",
          description: "description",
          status: mainUser.userId === profile.userId ? "active" : "unanswered",
          dtStart: newAppointment.dtStart,
          dtEnd: newAppointment.dtEnd,
          type: "event",
          customAppointmentTypesId: newAppointment.customAppointmentTypesId,
          userId: participantProfiles.map((participant) => participant.userId),
        })
          .then(async (response) => {
            if (response.id) {
              const participantProfilerData = participantProfiles?.map(
                (participant) => ({
                  userId: participant.userId,
                  name: participant.name || participant.username,
                  type: participant.type,
                  status: APPOINTMENT_STATUSES.UNANSWERED,
                })
              );
              const externalParticipants = externalUsers?.map(
                (participant) => ({
                  externalUserId: participant.id,
                  name: participant.name,
                  status: APPOINTMENT_STATUSES.UNANSWERED,
                  type: "external",
                })
              );
              const promise = CalendarService.createCalendarEventParticipant(
                mainUser.accountId,
                {
                  accountId: mainUser.accountId,
                  eventId: response.id,
                  participantProfilerData,
                  externalParticipants: externalParticipants,
                }
              );

              await promise;
              resolve(response);
              setIsLoading(false);
            } else {
              setIsLoading(false);
              reject(response);
              return;
            }
          })
          .catch((err) => {
            setIsLoading(false);
            reject(err);
          });
      });
    },
    [userCalendar, profile]
  );
  return [createAppointment, { isLoading }];
};

export const useAppointmentUpdate = () => {
  const [appointment, setAppointment] = useState(null);
  const { userCalendar } = useContext(UserContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateAppointment = useCallback(
    async (updatedAppointment) => {
      return new Promise(async (resolve, reject) => {
        setIsSuccess(false);
        setIsLoading(true);
        CalendarService.updateCalendarEvent(
          userCalendar.accountId,
          updatedAppointment
        )
          .then(async (response) => {
            if (response.id) {
              const resultAppointment = {
                ...response,
                dtConvertStart: new Date(Number(response.dtStart)),
                dtConvertEnd: new Date(Number(response.dtEnd)),
                dtStart: Number(response.dtStart),
                dtEnd: Number(response.dtEnd),
              };
              setAppointment(resultAppointment);
              setIsSuccess(true);
              setIsLoading(false);
              resolve(resultAppointment);
            } else {
              setIsLoading(false);
              reject(response);
            }
          })
          .catch((err) => {
            setIsLoading(false);
            reject(err);
          });
      });
    },
    [userCalendar]
  );

  const newAppointment = useMemo(() => {
    if (!appointment) return null;
    return convertData(appointment);
  }, [appointment]);

  return [updateAppointment, { isSuccess, isLoading, newAppointment }];
};

export const useTimeSlots = (
  participantProfile,
  date,
  durationInMillis,
  currentUserId,
  isVideoCall = false,
  isDataFetchingEnabled = true,
  isOwnTimeSlotsOnly = false
) => {
  if (!isDataFetchingEnabled) {
    return [[], [], { isLoading: false }];
  }
  const [timeSlots, setTimeSlots] = useState(null);
  const { profile, organization, userCalendar, user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [recommendedSlotsData, setRecommendedSlotsData] = useState([]);
  let recommendedSlots = [];
  let dateNext = date;
  let isCounsellor = user.roles.some(
    (role) =>
      role.name === USER_ROLES.COUNSELLOR_ROLE ||
      role.name === USER_ROLES.ALLBRY_COUNSELLOR_ROLE
  );

  const ownAppointmentsPromise = useMemo(() => {
    if (!date || !profile) {
      return Promise.resolve([]);
    }

    const dtStart = startOfDay(date).getTime();
    const dtEnd = endOfDay(add(date, { days: 30 })).getTime();
    const checkMultipleAccounts = isCounsellor ? true : false;
    return CalendarService.getEventsByCalendarId(
      profile.accountId,
      userCalendar.id,
      { dtStart, dtEnd },
      currentUserId ? [currentUserId] : null,
      checkMultipleAccounts,
      user.id
    );
  }, [profile, date, currentUserId, userCalendar]);

  const participantAppointmentsPromise = useMemo(() => {
    if (!date || !profile || !participantProfile.length) {
      return Promise.resolve([]);
    }

    const dtStart = startOfDay(date).getTime();
    const dtEnd = endOfDay(add(date, { days: 30 })).getTime();
    const checkMultipleAccounts = isCounsellor ? false : true;
    return CalendarService.getCalendarEventsByOwnerId(
      profile.accountId,
      participantProfile.map((participant) => participant.userId),
      { dtStart, dtEnd },
      currentUserId ? [currentUserId] : null,
      checkMultipleAccounts
    );
  }, [profile, participantProfile, date, currentUserId]);

  useEffect(() => {
    (async () => {
      if (
        !organization ||
        !durationInMillis ||
        !date ||
        (isOwnTimeSlotsOnly ? !isOwnTimeSlotsOnly : !participantProfile.length)
      ) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      const ownAppointments = await ownAppointmentsPromise;
      const participantAppointments = await participantAppointmentsPromise;

      const getSlots = async (SlotDate) => {
        const orgWorkingHours = getWorkingHours(
          organization.schedule,
          SlotDate
        );
        const workingHours = orgWorkingHours
          ? {
              start: orgWorkingHours.workTimeStart,
              end: orgWorkingHours.workTimeEnd,
            }
          : null;

        if (!workingHours) {
          setTimeSlots([]);
        }

        const allAppointments =
          [
            ...ownAppointments.filter((e) =>
              isSameDay(SlotDate, new Date(JSON.parse(e.dtStart)))
            ),
            ...participantAppointments.filter((e) =>
              isSameDay(SlotDate, new Date(JSON.parse(e.dtStart)))
            ),
          ] ?? [];
        const appointments = allAppointments.filter(
          (x) => x.status !== "cancelled"
        );
        const appointmentEvents = appointments.filter(
          (appointment) => appointment.type === "event"
        );

        const freeTimeEvents = appointments.filter(
          (appointment) => appointment.type === "freeTime"
        );

        const resultFreeTimeEvents = handleFreeTimeEventsIntersections(
          freeTimeEvents
        ).map((freeTime) => ({
          ...freeTime,
          dtStartFromDayStart:
            Number(freeTime.dtStart) - startOfDay(Number(freeTime.dtStart)),
          dtEndFromDayStart:
            Number(freeTime.dtEnd) - startOfDay(Number(freeTime.dtEnd)),
        }));

        const preparedTimeSlots = prepareTimeSlots(
          workingHours,
          appointmentEvents,
          SlotDate,
          durationInMillis,
          resultFreeTimeEvents,
          isCounsellor,
          isVideoCall
        );

        return preparedTimeSlots;
      };

      const preparedTimeSlots = await getSlots(date);

      const getRecommendedSlots = async () => {
        setIsLoading(true);
        if (preparedTimeSlots !== null && !preparedTimeSlots.length) {
          if (
            recommendedSlots.length == 3 ||
            dateNext > add(date, { days: 30 })
          ) {
            setRecommendedSlotsData(recommendedSlots);
            setIsLoading(false);
            return;
          } else {
            dateNext = startOfDay(add(dateNext, { days: 1 }));
            const data = await getSlots(dateNext);
            if (data.length) {
              recommendedSlots.push({ data: data, date: dateNext });
            }
            getRecommendedSlots();
          }
        } else {
          setIsLoading(false);
          return;
        }
      };
      getRecommendedSlots();
      setTimeSlots(preparedTimeSlots);
      setIsLoading(false);
    })();
  }, [
    organization,
    date,
    durationInMillis,
    ownAppointmentsPromise,
    participantAppointmentsPromise,
    participantProfile,
    isOwnTimeSlotsOnly,
  ]);

  return useMemo(() => {
    const result = timeSlots ?? [];
    return [result, recommendedSlotsData, { isLoading }];
  }, [timeSlots, isLoading, recommendedSlotsData]);
};

export const useFreeTimeList = (options = {}) => {
  const { userCalendar, profile } = useContext(UserContext);

  const [isCreating, setIsCreating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const [isError, setIsError] = useState(false);

  const [freeTimes, setFreeTimes] = useState([]);
  const [appointments, { isLoading: isFetching }] = useAppointmentList(options);
  const [updateAppointment] = useAppointmentUpdate();

  useEffect(() => {
    if (appointments?.length) {
      setFreeTimes(
        appointments.filter((appointment) => appointment.type === "freeTime")
      );
    }
  }, [appointments]);

  const createFreeTimes = useCallback(
    async (newAppointments) => {
      setIsError(false);
      setIsCreating(true);

      try {
        const data = newAppointments.map((newAppointment) => ({
          accountId: userCalendar.accountId,
          globalCalendarEventId: generateUUID(),
          calendarId: userCalendar.id,
          organizerId: profile.userId,
          organizerName: profile.name,
          title: "Free time",
          description: "free time",
          status: "active",
          dtStart: newAppointment.dtStart,
          dtEnd: newAppointment.dtEnd,
          type: "freeTime",
          customAppointmentTypesId: newAppointment.meetingType,
        }));

        const promises = CalendarService.createCalendarEventMultiple(
          userCalendar.accountId,
          {
            accountId: userCalendar.accountId,
            organizerId: profile.userId,
            calendarId: userCalendar.id,
            data: data,
            globalCalendarEventId: generateUUID(),
          }
        );

        const appointmentResponses = await promises;
        const preparedAppointmentsPromises = appointmentResponses.map(
          (appointmentResponse) =>
            prepareAppointment(appointmentResponse, profile)
        );
        const preparedAppointments = await Promise.all(
          preparedAppointmentsPromises
        );

        setFreeTimes((prev) => [
          ...prev,
          ...preparedAppointments.map((appointment) =>
            convertData(appointment)
          ),
        ]);
        setIsCreating(false);
      } catch (err) {
        setIsError(true);
        setIsCreating(false);
      }
    },
    [profile, userCalendar]
  );

  const deleteFreeTime = useCallback(async (accountId, id) => {
    setIsError(false);
    setIsDeleting(true);

    try {
      await CalendarService.deleteCalendarEventById(accountId, id);
      setFreeTimes((prev) =>
        prev.filter((appointment) => appointment.id !== id)
      );

      setIsDeleting(false);
    } catch (err) {
      setIsError(true);
      setIsDeleting(false);
    }
  }, []);

  const updateFreeTime = useCallback(
    async (newAppointment) => {
      setIsError(false);
      setIsUpdating(true);

      try {
        const updatedAppointment = await updateAppointment(newAppointment);
        setFreeTimes((prev) =>
          prev.map((appointment) => {
            if (appointment.id === updatedAppointment.id) {
              return updatedAppointment;
            }

            return appointment;
          })
        );

        setIsUpdating(false);
      } catch (err) {
        setIsError(true);
        setIsUpdating(false);
      }
    },
    [updateAppointment]
  );

  const sortedFreeTimes = useMemo(() => {
    if (!freeTimes) {
      return [];
    }

    return freeTimes.sort((a, b) => {
      if (a.dtStart < b.dtStart) {
        return -1;
      }

      if (b.dtStart < a.dtStart) {
        return 1;
      }

      return 0;
    });
  }, [freeTimes]);

  return [
    sortedFreeTimes,
    {
      isCreating,
      createFreeTimes,
      deleteFreeTime,
      isDeleting,
      isError,
      updateFreeTime,
      isUpdating,
      isFetching,
    },
  ];
};

export const useUnansweredEvent = (options = {}) => {
  const [unansweredEvent, setUnansweredEvent] = useState(null);
  const [distributedUsers, setDistributedUsers] = useState([]);

  const { profile } = useContext(UserContext);
  const isUnmounted = useRef();

  const fetchData = async () => {
    if (!profile) {
      return;
    }
    const res = await calendarGraphqlInstance.getUnAnsweredEvents(
      distributedUsers
    );
    let result = res;
    if (!options?.forAllAccount) {
      result = res.filter((e) => e.accountId === profile.accountId);
    }
    setUnansweredEvent(result);
  };

  useEffect(() => {
    (async () => {
      fetchData();
      return () => {
        isUnmounted.current = true;
      };
    })();
  }, [profile, distributedUsers]);

  return [unansweredEvent, fetchData, setDistributedUsers];
};

export const useAppointmentDates = ({ calendarId, startDate, endDate }) => {
  const [appointmentDates, setAppointmentDates] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const isUnmounted = useRef();
  useEffect(() => {
    (async () => {
      if (!calendarId || !startDate || !endDate) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const response =
        await calendarGraphqlInstance.getAllCalenderEventDatesById(
          calendarId,
          startDate,
          endDate
        );

      if (!isUnmounted.current) {
        setAppointmentDates(response);
        setIsLoading(false);
      }

      return () => {
        isUnmounted.current = true;
      };
    })();
  }, [startDate, endDate, calendarId]);

  return useMemo(() => {
    const result = appointmentDates?.map((x) =>
      format(new Date(parseInt(x)), "yyyy-MM-dd")
    );
    return [result, { isLoading }];
  }, [appointmentDates, isLoading]);
};

export const useUserCalendar = (accountId, userId) => {
  const [userCalendar, setUserCalendar] = useState(null);
  const { profile: currentProfile } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      if (!currentProfile || !accountId || !userId) {
        return;
      }

      const userCalendarResponse = await CalendarService.getCalendarByUserId(
        accountId,
        userId
      );
      setUserCalendar(userCalendarResponse);
    })();
  }, [currentProfile, accountId, userId]);

  return useMemo(() => {
    if (!userCalendar) {
      return null;
    }

    return userCalendar;
  }, [currentProfile, userCalendar, accountId, userId]);
};
