export const ROOT = "/";

export const AUTH_ROOT_SSO = `${ROOT}sso`;

export const AUTH_ROOT_LOGIN = `${ROOT}login`;
export const SURVEY = `${ROOT}survey`;

export const AUTH_LOGIN_STEPS = {
  LOGIN_CODE: `${AUTH_ROOT_LOGIN}?code`,
  LOGIN_CREATE: `${AUTH_ROOT_LOGIN}?create`,
  LOGIN_EMAIL: `${AUTH_ROOT_LOGIN}?email`,
  LOGIN_PASSWORD: `${AUTH_ROOT_LOGIN}?password`,
  LOGIN_PAYMENT: `${AUTH_ROOT_LOGIN}?payment`,
  LOGIN_PAYMENT_APP: `${AUTH_ROOT_LOGIN}?payment-app`,
  LOGIN_SUCCESS: `${AUTH_ROOT_LOGIN}?success`,
  LOGIN_PAYMENT_SUCCESS: `${AUTH_ROOT_LOGIN}?payment-confirmation-order-id`,
};

export const AUTH_ROOT_SIGN_UP_CONSUMER = `${ROOT}sign-up-consumer/:accountId`;
export const AUTH_SIGN_UP_CONSUMER_STEPS = {
  SIGN_UP_CREATE: `${AUTH_ROOT_SIGN_UP_CONSUMER}?create`,
  SIGN_UP_PAYMENT: `${AUTH_ROOT_SIGN_UP_CONSUMER}?payment`,
  SIGN_UP_SUCCESS: `${AUTH_ROOT_SIGN_UP_CONSUMER}?success`,
};

export const AUTH_ROOT_FORGOT = `${ROOT}forgot`;
export const AUTH_FORGOT_STEPS = {
  FORGOT_CHECK: `${AUTH_ROOT_FORGOT}?check`,
  FORGOT_PASSWORD: `${AUTH_ROOT_FORGOT}?create`,
};

export const PROFILE = `${ROOT}profile`;

export const EDIT_PROFILE = `${PROFILE}/:id/edit`;
export const PROFILE_STUDENT_VIEW_NOTES = `${PROFILE}/:id/notes`;
export const PROFILE_STUDENT_VIEW_STATISTICS = `${PROFILE}/:id/statistics`;
export const PROFILE_STUDENT_VIEW_NOTES_ADD = `${PROFILE_STUDENT_VIEW_NOTES}/add`;
export const PROFILE_STUDENT_VIEW_NOTE = `${PROFILE_STUDENT_VIEW_NOTES}/:noteId`;

export const PROFILE_STUDENT_VIEW_REPORT = `${PROFILE}/:id/report/:reportId`;

export const SETTINGS = `${ROOT}settings`;

export const SURVEY_ROOT = "/";

export const NOT_FOUND = "*";

export const APPOINTMENTS = `${ROOT}appointments`;
export const APPOINTMENT_DETAIL = `${ROOT}appointments/:id`;
export const APPOINTMENTS_BOOKING = `${APPOINTMENTS}/book`;
export const APPOINTMENTS_FREE_TIME = `${APPOINTMENTS}/free`;

export const COUNSELLORS = `${ROOT}counsellors`;

export const MESSAGES = `${ROOT}messages`;
export const STUDENTS = `${ROOT}students`;
export const REPORTS = `${ROOT}reports`;

export const VIDEO_ROOT = `${ROOT}meet`;
export const VIDEO_ROOM = `${VIDEO_ROOT}/:id`;

export const ORGANIZATION = `${ROOT}organization`;
export const STATISTICS = `${ROOT}statistics`;

export const ACCOUNT = `account`;
export const ACCOUNT_SELECTION = `${ROOT}accountSelection`;
export const USERS_ACCOUNT_SELECTION = `${ROOT}usersAccountSelection`;

export const USERS = `${ROOT}users`;
export const ADD_USER = `${USERS}/add`;
export const EDIT_USER = `${USERS}/:id/${ACCOUNT}/:accountId/edit`;

export const QUESTIONS = `${ROOT}survey/:id`;

export const CONFIG = `${ROOT}config`;
export const ADD_CONFIG = `${CONFIG}/create/:questionNumber`;
export const CREATE_OWN_TEMPLATE = `${CONFIG}/createOwnTemplate`;

export const SURVEY_AND_CHECK_IN = `${ROOT}checkInsAndSurveys`;

export const CHECK_IN = `${ROOT}checkIn`;

export const EXTERNAL_USER = `${ROOT}join`;

export const ANONYMOUS_CHAT = `${ROOT}anonymousChat`;